import Cookies from 'js-cookie'
import {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import Define from '../constants/define'
import authApi from '../http/authApi'
import { handleRefreshToken } from '../http/clientAPI'

const AuthGuard: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const baseURL = process.env.REACT_APP_API_ENDPOINT?.replace('/api/v1', '')
  const sUrl = process.env.REACT_APP_S_URL
  const ssoURL = `${baseURL}/?s_url=${sUrl}`
  const cookieValue = Cookies.get('SSO_COOKIE-X')
  const [isHide, setIsHide] = useState<boolean>(true)

  const waitCookie = async () => {
    return await new Promise((resolve, reject) => {
      let timeOut = 0
      const interval = setInterval(() => {
        timeOut += 1
        if (cookieValue) {
          resolve('')
          clearInterval(interval)
        } else {
          if (timeOut === 50) {
            reject('Cookie not existed')
            clearInterval(interval)
          }
        }
      }, 100)
    })
  }

  const waitLocalStorageGetItem = () => {
    return new Promise((resolve) => {
      localStorage.getItem(Define.USERNAME)
      localStorage.getItem(Define.CODE)
      localStorage.getItem(Define.ROLE)
      localStorage.getItem(Define.USER_ID)
      resolve('')
    })
  }

  useEffect(() => {
    // check if access token is coming to expire to refresh
    const interval = setInterval(() => {
      const expiredAtString = Cookies.get('ACCESS_TOKEN_EXP_AT')
      if (expiredAtString) {
        const timestampExpireToken = Date.parse(expiredAtString)
        // If the token is going to expire in 10 minutes, refresh it
        if (timestampExpireToken - Date.now() < 600 * 1000) {
          handleRefreshToken()
        }
      }
      // run check every 3 minutes
    }, 180 * 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    async function getMe() {
      try {
        // wait until cookie is ready
        await waitCookie()

        const res = await authApi.getMe()
        const user = res.data.entry

        localStorage.setItem(
          Define.USERNAME,
          `${user.code}-${user.pre_nom} ${user.nom}`
        )

        localStorage.setItem(Define.CODE, user.code)
        localStorage.setItem(Define.ROLE, user.role)
        localStorage.setItem(Define.MAIL, user.mail)
        if (localStorage.getItem(Define.USER_ID) !== user.id) {
          localStorage.setItem(Define.USER_ID, user.id)
          waitLocalStorageRemove()
        }
        waitLocalStorageGetItem().then(() => {
          setIsHide(false)
        })
      } catch (e) {
        // if cookie is expired, redirect to login page
        window.location.replace(ssoURL)
      }
    }

    getMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const waitLocalStorageRemove = () => {
    return new Promise((resolve) => {
      localStorage.removeItem(Define.CHOOSING_COMPANY)
      localStorage.removeItem(Define.CHOOSING_COMPANY_CODENOM)
      localStorage.removeItem(Define.CHOOSING_CLIENT)
      localStorage.removeItem(Define.CHOOSING_CLIENT_CODENOM)
      localStorage.removeItem(Define.CHOOSING_WAREHOUSE)
      localStorage.removeItem(Define.CHOOSING_WAREHOUSE_CODENOM)
      resolve('')
    })
  }

  if (!isHide) {
    if (!cookieValue) {
      if (process.env.NODE_ENV.toString() === 'development') return null
      else window.location.replace(ssoURL)
    } else {
      return <>{children as ReactElement}</>
    }
  }
  return <></>
}

export default AuthGuard
